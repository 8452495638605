@use 'mixins' as *;

.link {
  display: inline-flex;
  align-items: center;
  color: var(--text-color, #000);
  gap: 0.5rem;
  transition: 0.2s;
  cursor: pointer;
  text-decoration: underline;

  &[href^='tel'] {
    text-decoration: none;
  }

  @include hover {
    &,
    & > * {
      color: var(--text-color-hover, #000);
      fill: var(--text-color-hover, #000);
    }
  }

  &:focus {
    color: var(--text-color-hover, #000);
    box-shadow: none !important;
    outline: 0;
    border: 2px solid Highlight;
    border: 3px solid -webkit-focus-ring-color !important;
  }

  &:active,
  & > *:active {
    color: inherit;
  }

  &:visited,
  & > *:visited {
    color: inherit;
  }

  &__wrapper {
    display: block;
    text-decoration: none;
  }
}
