.heading {
  text-align: var(--text-align, start);
  font-family: var(--font-family-hero, --font-family);
  color: var(--text-color, #000);
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing);
  font-weight: var(--font-weight);
  font-size: var(--font-size);

  &__h1 {
    font-weight: var(--font-weight-h1, 400);
    font-size: var(--font-size-h1, 1.75rem);
    letter-spacing: var(--letter-spacing-h1, 0);
    line-height: var(--line-height-h1, 1);
  }

  &__h2 {
    font-weight: var(--font-weight-h2, 400);
    font-size: var(--font-size-h2, 1.25rem);
    letter-spacing: var(--letter-spacing-h2, 0);
    line-height: var(--line-height-h2, 1);
  }

  &__h3 {
    font-weight: var(--font-weight-h3, 400);
    font-size: var(--font-size-h3, 0.8125rem);
    letter-spacing: var(--letter-spacing-h3, 0);
    line-height: var(--line-height-h3, 1);
  }

  &__h4 {
    font-weight: var(--font-weight-h4, 400);
    font-size: var(--font-size-h4, 0.8125rem);
    letter-spacing: var(--letter-spacing-h4, 0);
    line-height: var(--line-height-h4, 1);
  }

  &__h5 {
    font-weight: var(--font-weight-h5, 400);
    font-size: var(--font-size-h5, 0.8125rem);
    letter-spacing: var(--letter-spacing-h5, 0);
    line-height: var(--line-height-h5, 1);
  }

  &__h6 {
    font-weight: var(--font-weight-h6, 400);
    font-size: var(--font-size-h6, 0.8125rem);
    letter-spacing: var(--letter-spacing-h6, 0);
    line-height: var(--line-height-h6, 1);
  }

  &__p1-preamble {
    font-weight: var(--font-weight-p1, 400);
    font-size: var(--font-size-p1, 0.875rem);
    letter-spacing: var(--letter-spacing-p1, 0);
    line-height: var(--line-height-p1, 1);
  }

  &__p2-paragraph {
    font-weight: var(--font-weight-p2, 400);
    font-size: var(--font-size-p2, 1.25rem);
    letter-spacing: var(--letter-spacing-p2, 0);
    line-height: var(--line-height-p2, 1);
  }

  &__p3-paragraph {
    font-weight: var(--font-weight-p3, 400);
    font-size: var(--font-size-p3, 1.25rem);
    letter-spacing: var(--letter-spacing-p3, 0);
    line-height: var(--line-height-p3, 1);
  }

  &__sub-heading {
    font-weight: var(--font-weight-subheading, 400);
    font-size: var(--font-size-subheading, 0.625rem);
    letter-spacing: var(--letter-spacing-subheading, 0);
    line-height: var(--line-height-subheading, 1);
    text-transform: uppercase;
  }
}
