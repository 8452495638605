@use 'mixins' as *;

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: fit-content;
  cursor: pointer;
  transition: 0.2s;
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  font-size: var(--font-size-subheading);

  // line-height: var(--line-height-subheading);
  font-weight: var(--font-weight-subheading);
  letter-spacing: var(--letter-spacing-subheading, 0);
  color: var(--button-text-color, #000);
  block-size: var(--button-block-size, 1rem);
  min-inline-size: var(--button-min-inline-size, 150px);
  padding: var(--button-padding-block, 0.5rem);
  margin-block: var(--button-margin-block, 0);
  background-color: var(--button-bg-color, transparent);

  &--rounded {
    border-radius: 50px;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--center {
    margin-inline: auto;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    box-shadow: none !important;
    outline: 0;
    border: 2px solid Highlight;
    border: 3px solid -webkit-focus-ring-color !important;
  }

  @include hover {
    background-color: var(--button-bg-color-hover, transparent);
    color: var(--button-text-color-hover, #000);
  }
}
